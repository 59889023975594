import wordmark from 'mastodon/../images/logo-symbol-wordmark.svg';
import logo from 'mastodon/../images/logo.svg';

export const WordmarkLogo: React.FC = () => (
  <img src={wordmark} alt='meme' className='logo logo--wordmark' />
);

export const SymbolLogo: React.FC = () => (
  <img src={logo} alt='meme' className='logo logo--icon' />
);
